import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import alerts from '@astina/paloma-common-ui/src/services/Alerts';
import paloma from '@astina/paloma-common-ui/src/services/PalomaBackend';
import translate from '@astina/paloma-common-ui/src/services/Translator';
import ValidationUtils from "@astina/paloma-common-ui/src/services/ValidationUtils";
import env from '../services/AppEnv';
import portalConfig from "../services/PortalConfig";
export function exportProducts(searchRequest, format, exportId) {
    return new ExportProducts(searchRequest, format, exportId)
        .enqueue();
}
export default class ExportProducts extends AbstractCommand {
    searchRequest;
    format;
    exportId;
    statusCheckIntervalMs = 5000;
    maxNumStatusChecks = portalConfig.productExport.maxExportTimeMs / this.statusCheckIntervalMs;
    constructor(searchRequest, format, exportId) {
        super();
        this.searchRequest = searchRequest;
        this.format = format;
        this.exportId = exportId;
    }
    execute() {
        const numStatusChecks = 0;
        return paloma
            .execute(this.name(), {
            searchRequest: this.searchRequest,
            format: this.format,
            locale: env.locale,
            exportId: this.exportId
        })
            .then(exportStatus => {
            return this.checkExportStatus(exportStatus, numStatusChecks);
        })
            .catch((error) => {
            try {
                const validation = ValidationUtils.createValidation(error);
                ValidationUtils.alertAll(validation);
            }
            catch (e) {
                alerts.error(translate(`command.${this.name()}.error`));
            }
            throw error;
        });
    }
    checkExportStatus(exportStatus, numStatusChecks) {
        if (numStatusChecks >= this.maxNumStatusChecks) {
            throw new Error('Maximum time for export elapsed. Giving up!');
        }
        return paloma
            .execute(this.name(), {
            locale: env.locale,
            exportId: exportStatus.exportId
        }).then(status => {
            if (status.downloadUrl) {
                this.downloadUrl(status.downloadUrl);
            }
            else {
                numStatusChecks++;
                return this.sleep(this.statusCheckIntervalMs).then(() => {
                    return this.checkExportStatus(exportStatus, numStatusChecks);
                });
            }
        })
            .catch((error) => {
            try {
                const validation = ValidationUtils.createValidation(error);
                ValidationUtils.alertAll(validation);
            }
            catch (e) {
                alerts.error(translate(`command.${this.name()}.error`));
            }
            throw error;
        });
    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    downloadUrl(url) {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.download = '';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    name() {
        return 'export_products';
    }
    icon() {
        return 'download';
    }
}
