import config from '@astina/paloma-shop-portal-ui/src/services/PortalConfig';
import { createPortalUiApp } from "@astina/paloma-shop-portal-ui/src/init";
import InfoNavItems from "@/components/InfoNavItems.vue";
import ContactView from "./views/ContactView.vue";
import ImprintView from "@/views/ImprintView.vue";
config.headerInfoComponent = InfoNavItems;
config.customRoutes = [
    {
        name: 'contact',
        path: '/contact',
        component: ContactView,
    },
    {
        name: 'imprint',
        path: '/imprint',
        component: ImprintView,
    },
];
config.cartDeliveryDaysMaxWeeksAhead = 12;
config.cartDeliveryDaysVisibleWeeks = 2;
config.articleOrderingUnitSelection = 'deny';
config.watchlistsSharingEnabled = false;
config.accessRequestsEnabled = false;
config.displayProductUnits = ['trade_unit'];
config.showPriceUnavailableAltText = true;
config.showCartComment = false;
config.showCartCustomerOrderNumber = true;
config.productExport.formats = [{
        name: 'elsa_portal_products',
        requiresAuthentication: true
    }];
const app = createPortalUiApp();
app.mount('#app');
