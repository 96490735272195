import palomaBackend from "./PalomaBackend";
import { handleHttpError } from "./Alerts";
import { handleHttpValidationError } from "./ValidationUtils";
const http = palomaBackend.http;
class SecretsRepository {
    saveSecret(app, secret) {
        return http
            .post(`${app}/secrets`, secret)
            .then(response => response.data)
            .catch(handleHttpValidationError)
            .catch(handleHttpError);
    }
    deleteSecret(app, secret) {
        const id = typeof secret === 'object' ? secret.secretId : secret;
        return http
            .delete(`${app}/secrets/${id}`)
            .then(response => response.data)
            .catch(handleHttpValidationError)
            .catch(handleHttpError);
    }
    getSecretValue(app, secret) {
        const id = typeof secret === 'object' ? secret.secretId : secret;
        return http
            .get(`${app}/secrets/${id}`)
            .then(response => response.data)
            .catch(handleHttpValidationError)
            .catch(handleHttpError);
    }
}
export default new SecretsRepository();
