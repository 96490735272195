<template>
<div class="contact">
    <div class="container">

        <h1>{{ $t('contact.title') }}</h1>

        <p>
            {{ $t('contact.text') }}
        </p>

        <p>
            <a :href="$t('contact.url')" target="_blank">
                <icon name="arrow-right"/>
                {{ $t('contact.link') }}
            </a>
        </p>

    </div>
    <div class="container">
        <h2>Elsa Gastro</h2>
        <a href="mailto:sec.gastro@elsagroup.ch">
            <icon name="envelope"/>
            sec.gastro@elsagroup.ch
        </a>
    </div>
</div>
</template>

<style scoped lang="scss">

    @import "~@astina/paloma-shop-portal-ui/src/css/theme";

    h1 {
        margin-bottom: 2rem;
    }

    .contact {
        margin-top: 2rem;
    }

    .contact p {
        font-size: 1.1rem;
    }

    .contact a {
        font-size: 1.1rem;
        font-weight: $weight-text-medium;
        color: $primary-9;
    }

</style>
<script setup lang="ts">
</script>