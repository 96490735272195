<template>
    <div class="imprint">
        <div class="container">

            <h1>{{ $t('nav.imprint') }}</h1>

            <h4>{{ $t('nav.imprint.elsa.publisher') }} / {{ $t('nav.imprint.elsa.distributor') }}</h4>

            <p>Elsa Group SA<br>Rte de Payerne 2-4<br>1470 Estavayer-le-Lac</p>

        </div>
    </div>
</template>

<style scoped lang="scss">

    @import "~@astina/ui-theme/src/css/theme";

    h1 {
        margin-bottom: 2rem;
    }

    .imprint {
        margin-top: 2rem;
    }

</style>
<script setup lang="ts">
</script>