import mitt from 'mitt';
const emitter = mitt();
/**
 * New event bus with a private emitter.
 */
export class EventBus {
    emitter;
    constructor() {
        this.emitter = mitt();
    }
    $on(type, handler) {
        emitter.on(type, handler);
    }
    $off(type, handler) {
        emitter.off(type, handler);
    }
    $emit(type, event) {
        emitter.emit(type, event);
    }
}
/**
 * Emit events on the shared emitter.
 */
export default {
    $on: (type, handler) => on(emitter, type, handler),
    $off: (type, handler) => off(emitter, type, handler),
    $emit: (type, event) => emitter.emit(type, event)
};
function on(em, types, handler) {
    if (typeof types === 'string') {
        emitter.on(types, handler);
    }
    else {
        types.forEach(t => emitter.on(t, handler));
    }
}
function off(em, types, handler) {
    if (typeof types === 'string') {
        emitter.off(types, handler);
    }
    else {
        types.forEach(t => emitter.off(t, handler));
    }
}
