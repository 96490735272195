import events from "@astina/paloma-common-ui/src/services/EventBus";
import palomaWebsocket from "@astina/paloma-common-ui/src/services/WebSocket";
import log from "@astina/paloma-common-ui/src/services/Logger";
import env from '@astina/paloma-common-ui/src/services/AppEnv';
class InventoryListener {
    subscription = null;
    start() {
        if (!env.user.authenticated) {
            return;
        }
        if (this.subscription) {
            return;
        }
        log('Initialising inventory update listener');
        this.subscription = palomaWebsocket.subscribe('/queue/inventory-update', message => {
            const update = JSON.parse(message.body);
            log('Received inventory update', update);
            events.$emit(`shop.inventory-update.${update.sku}`, update);
        });
    }
    ;
    stop() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }
}
const inventoryListener = new InventoryListener();
export default inventoryListener;
