import serviceLocator from "./ServiceLocator";
import {} from "./UserSettings";
class FavouriteItemsService {
    /**
     * Local copy in order to return them synchronously.
     */
    _itemIds = {};
    isAvailable() {
        return serviceLocator.has('paloma_user_settings');
    }
    toggleItem(collection, itemId) {
        if (!this.isAvailable()) {
            return Promise.reject();
        }
        const palomaUserSettings = serviceLocator.get('paloma_user_settings');
        return palomaUserSettings.get('favourites', '_all')
            // @ts-ignore
            .then((data) => {
            if (!data) {
                data = {};
            }
            if (!data[collection]) {
                data[collection] = [];
            }
            const itemIds = data[collection];
            const pos = itemIds.indexOf(itemId);
            if (pos === -1) {
                itemIds.push(itemId);
            }
            else {
                itemIds.splice(pos, 1);
            }
            palomaUserSettings.set('favourites', '_all', data);
            this._itemIds = data;
        });
    }
    isSelected(collection, itemId) {
        return this.itemIds(collection).indexOf(itemId) !== -1;
    }
    itemIds(collection) {
        return this._itemIds[collection] || [];
    }
    loadFavourites() {
        if (!this.isAvailable()) {
            return;
        }
        const palomaUserSettings = serviceLocator.get('paloma_user_settings');
        palomaUserSettings.get('favourites', '_all')
            // @ts-ignore
            .then((data) => {
            this._itemIds = data || {};
        });
    }
}
export default new FavouriteItemsService();
