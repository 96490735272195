class PortalConfig {
    /**
     * Vue.JS component class to be rendered inside the info dropdown menu.
     */
    headerInfoComponent = null;
    /**
     * Projects can supply their own routes to be added to the default routes.
     */
    customRoutes = [];
    /**
     * How many weeks ahead delivery dates should be selectable.
     * Note: should be matched with `paloma-shop.delivery-options.maximum-days-ahead`.
     */
    cartDeliveryDaysMaxWeeksAhead = 2;
    /**
     * How many weeks should be displayed at once when selecting delivery dates.
     */
    cartDeliveryDaysVisibleWeeks = 3;
    /**
     * Whether to allow selecting the product unit to order if there are
     * multiple. If this is set to {@code deny}, the user will not get a choice.
     * By default, the "defaultOrderingUnit" is selected.
     */
    articleOrderingUnitSelection = 'deny';
    /**
     * Whether watchlist sharing features are enabled in the B2B portal UI.
     */
    watchlistsSharingEnabled = false;
    /**
     * How many items of a watchlist will be loaded at the same time.
     */
    watchlistsItemPageSize = 25;
    extensions = {
        /**
         * Custom component to be rendered below the watchlist access token in
         * the watchlist share modal.
         */
        watchlistAccessToken: null,
    };
    /**
     * Whether Access Requests feature is enabled in the B2B portal UI.
     */
    accessRequestsEnabled = true;
    /**
     * Array defining the unitCode of ProductUnit to display in the B2B portal UI.
     * e.g. ['PC']
     */
    displayProductUnits;
    showPriceUnavailableAltText = false;
    /**
     * CartView: show comment text area.
     * historical default value.
     */
    showCartComment = true;
    /**
     * CartView: show customer order number text field.
     * new feature, default disabled.
     */
    showCartCustomerOrderNumber = false;
    /**
     * CartView: maximum length of customer order number text field.
     * Only relevant when showCartCustomerOrderNumber = true.
     */
    cartCustomerOrderNumberMaxLength;
    /**
     * Configuration to display product variants with options as matrix.
     * If a product has variants with options matching a config entry (exactly),
     * the variants will be shown as a matrix.
     */
    productOptionsMatrices = [
        {
            optionX: 'size',
            optionY: 'color',
        },
        {
            optionX: 'size',
            optionY: 'color',
            optionZ: 'length',
        },
    ];
    productExport = {
        maxExportTimeMs: 900000, // 15min
        formats: [],
    };
}
const config = new PortalConfig();
export default config;
